export default {
   salesCommissions: {
      partnerUser: {
         loading: false,
         data: [],
         pagination: {
            currentPage: 1,
            tokenPages: [],
            totalPages: 0,
         }
      }
   }
}
