import axiosClient from '@/axios'
import { db, firestore } from "../../../../../plugins/firebase";
import {v4 as uuidV4} from "uuid";
import store from "../store/state";

const routeAuthenticated = '/auth/articles'
const articlesRef = db.collection('articles');
const briefArticlesRef = db.collection('briefArticles');
const categoriesArticlesRelationRef = db.collection('categoriesArticlesRelation');

const getArticles = async (pageToken = '', pageSize = 10) => {
   let results = { data: [] };

   if (pageToken === ''){
      const articles = await articlesRef.orderBy('createdAt', 'desc').limit(10).get();
      articles.forEach((doc) => {
         let item = doc.data();
         results.data.push(item);
      });
   }
   else {
      const tokens = [...store.articles.pagination.tokenPages];
      const tokenPage = tokens.find((element) => element.token === pageToken);

      const data = [...store.articles.pagination.data[ tokenPage.page - 1]];
      let uuids = data.map((element) => element.uuid);

      const articles = await articlesRef.where(firestore.FieldPath.documentId(), 'in', uuids).orderBy('createdAt', 'desc').get();
      articles.forEach((doc) => {
         let item = doc.data();
         results.data.push(item);
      });
   }

   return results
}

const getAllArticlesPaginated = async (pageSize = 10) => {
   const results = [];

   const briefArticles = await briefArticlesRef.orderBy('createdAt', 'desc').get();

   briefArticles.forEach((doc) => {
      let item = doc.data();
      results.push(item);
   });

   const totalPages = Math.ceil(results.length / pageSize);
   const tokens = [];
   const finalResults = [];

   for (let i = 1; i <= totalPages; i++) {
      tokens.push({ page: i, token: uuidV4() });

      let internalArray = [];
      for (let j = 0; j < pageSize; j++) {
         const position = (i - 1) * pageSize + j;
         if (results[position] === undefined) break;

         internalArray.push(results[position]);
      }
      finalResults.push(internalArray);
   }

   const result = {
      data: {
         totalPages,
         data: finalResults,
         tokens
      }
   };

   return result;
}

const get = async uuid => {
   return await axiosClient.get(`${routeAuthenticated}/get/${uuid}`);
}

const getAllArticlesCategoriesRelation = async () => {
   const categoriesArticlesRelation = await categoriesArticlesRelationRef.get();

   const results = [];
   categoriesArticlesRelation.forEach((doc) => {
      let item = doc.data();

      results.push(item);
   });

   return results;
}

const create = async article => {
   return await axiosClient.post(`${routeAuthenticated}/create`, article);
}

const edit = async article => {
   if (article.imageUrlToDelete !== null) {
      const regex = /images%2F([^.]+)\.([a-zA-Z]+)\?/;
      const imageUrlString = article.imageUrlToDelete.match(regex);

      let imageUrl = null;
      let extension = null;
      if (imageUrlString && imageUrlString[1] && imageUrlString[2]) {
         extension = imageUrlString[2];
         imageUrl = imageUrlString[1] + `.${extension}`;
      }
      article.imageUrlToDelete = imageUrl;
   }

   return await axiosClient.put(`${routeAuthenticated}/edit/${article.uuid}`, article);
}

const editStatus = async (status, uuid) => {
   return await axiosClient.put(`${routeAuthenticated}/edit-status/${uuid}`, {status});
}

const editSubscriptionType = async (subscriptionType, uuid) => {
   return await axiosClient.put(`${routeAuthenticated}/edit-subscription-type/${uuid}`, {subscriptionType});
}

const search = async (title) => {
   //TODO ---> optimize search, I already have the articles I don't have to search again.
   const articles = await articlesRef.get();

   const results = [];
   articles.forEach((doc) => {
      let item = doc.data();
      if (item.title) {
         const titleNormalized = item.title.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
         const searchNormalized = title.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

         if (titleNormalized.includes(searchNormalized))
            results.push(item);
      }
   });

   return results;
}

const destroy = async (uuid, allArticlesCategoriesRelation, image) => {
   const regex = /images%2F([^.]+)\.([a-zA-Z]+)\?/;
   const imageNameString = image.match(regex);

   let imageName = null;
   let extension = null;
   if (imageNameString && imageNameString[1] && imageNameString[2]) {
      extension = imageNameString[2];
      imageName = imageNameString[1] + `.${extension}`;
   }

   const data = {
      allArticlesCategoriesRelation,
      imageName
   };
   return await axiosClient.delete(`${routeAuthenticated}/delete/${uuid}`, { data: data } );
}

export default {
   create,
   get,
   getArticles,
   getAllArticlesPaginated,
   getAllArticlesCategoriesRelation,
   edit,
   editStatus,
   editSubscriptionType,
   search,
   destroy
}
