import {db, firestore} from "@/plugins/firebase";
import store from "../store/state";
import {v4 as uuidV4} from "uuid";
import {extractYearAndMonth, getFourPreviousMonths, currentYear as currentYearDate, currentMonth as currentMonthDate} from "@/utils/report-service-methods";
import {PAYMENT_APPLE_STATUS, PAYMENT_GOOGLE_PLAY_STATUS, PAYMENT_PAYPAL_STATUS} from "@/utils/payment-status";

const routeAuthenticated = '/auth/sales-commission'

const currentYear = currentYearDate();
const currentMonth = currentMonthDate();

const usersRef = db.collection('users');
const signaturesRef = db.collection('signatures');
const couponsRef = db.collection('coupons');

// FOR THE TABLE
const getAllPartnersPaginatedTable = async (pageSize = 10) => {
   const results = [];

   const users = await usersRef.where('profile', '==', 'partner')
                                                         .orderBy('createdAt', 'desc').get();

   users.forEach((doc) => {
      let item = doc.data();
      results.push(item);
   });

   const totalPages= Math.ceil(results.length / pageSize);
   const tokens = [];
   const partnerUsers = [];

   for (let i = 1; i <= totalPages; i++) {
      tokens.push({ page: i, token: uuidV4() });

      let internalArray = [];
      for (let j = 0; j < pageSize; j++) {
         const position = (i - 1) * pageSize + j;
         if (results[position] === undefined) break;

         internalArray.push(results[position]);
      }
      partnerUsers.push(internalArray);
   }

   const result = {
      data: {
         totalPages,
         data: partnerUsers,
         tokens
      }
   };

   return result;
}

const getPartnersSalesCommissionsInfoTable = async (pageToken = '', pageSize = 10) => {
   let partnerUsers = { data: [] };
   let uuids = [];

   if (pageToken === ''){
      const data = [...store.salesCommissions.partnerUser.data[0]];
      uuids = data.map((element) => element.id);

      partnerUsers.data = data;
   }
   else {
      const tokens = [...store.salesCommissions.partnerUser.pagination.tokenPages];
      const tokenPage = tokens.find((element) => element.token === pageToken);

      const data = [...store.salesCommissions.partnerUser.data[ tokenPage.page - 1]];
      uuids = data.map((element) => element.id);

      partnerUsers.data = data;
   }

   // verify if partnerUsers already have all information's, only if you " don't have it ", you'll need to load
   if (!partnerUsers.data[0].hasOwnProperty('totalCommission')) {
      const coupons = await couponsRef.get();

      // get all coupons related with the partners
      const partnerInfoResults = [];
      coupons.forEach((doc) => {
         let couponItem = doc.data();

         const partner = partnerUsers.data.find((partner) => partner.id === couponItem.userId);

         if (partner) {
            partnerInfoResults.push({
               partnerUuid: partner.id, partnerName: partner.name, couponUuid: couponItem.uuid,
               couponName: couponItem.name, partnerPercentage: couponItem.partnerPercentage,
               planUuid: couponItem.planUuid, total: 0
            });
         }
      });

      const signatures = await signaturesRef.where('couponId', '!=', null)
         .where('price', '>', 0).get();
      signatures.forEach((doc) => {
         let itemSignature = doc.data();

         if (itemSignature.lastPlatform === 'app_store' || itemSignature.hasOwnProperty('appStoreTransactions')){
            itemSignature.appStoreTransactions.forEach((transaction) => {

               const { year, month } = extractYearAndMonth(transaction.date);

               // Ensure the count is only for the current year and current month
               if (PAYMENT_APPLE_STATUS.includes(transaction.status.toUpperCase()) && year == currentYear && month == currentMonth){
                  partnerInfoResults.forEach((partnerInfo) => {
                     if (partnerInfo.couponUuid == itemSignature.couponId)
                        partnerInfo.total += itemSignature.price;
                  });
               }
            });
         }
         if (itemSignature.lastPlatform === 'paypal' || itemSignature.hasOwnProperty('payPalTransactions')){
            itemSignature.payPalTransactions.forEach((transaction) => {

               const { year, month } = extractYearAndMonth(transaction.date);

               // Ensure the count is only for the current year and current month
               if (PAYMENT_PAYPAL_STATUS.includes(transaction.status.toUpperCase()) && year == currentYear && month == currentMonth){
                  partnerInfoResults.forEach((partnerInfo) => {
                     if (partnerInfo.couponUuid == itemSignature.couponId)
                        partnerInfo.total += itemSignature.price;
                  });
               }
            });
         }
         if (itemSignature.lastPlatform === 'google_play' || itemSignature.hasOwnProperty('googlePlayTransactions')){
            itemSignature.googlePlayTransactions.forEach((transaction) => {

               const { year, month } = extractYearAndMonth(transaction.date);

               // Ensure the count is only for the current year and current month
               if (PAYMENT_GOOGLE_PLAY_STATUS.includes(transaction.status) && year == currentYear && month == currentMonth){
                  partnerInfoResults.forEach((partnerInfo) => {
                     if (partnerInfo.couponUuid == itemSignature.couponId)
                        partnerInfo.total += itemSignature.price;
                  });
               }
            });
         }
      });

      partnerUsers.data.forEach((partner) => {
         partnerInfoResults.forEach((partnerInfoResult) => {

            if (partner.id == partnerInfoResult.partnerUuid){
               if (partner.hasOwnProperty('total')){
                  partner.total += partnerInfoResult.total;
                  partner.totalCommission = partner.totalCommission + (partnerInfoResult.total * (partnerInfoResult.partnerPercentage / 100));

                  partner.couponsName = [...partner.couponsName, partnerInfoResult.couponName];
                  partner.couponsUuid = [...partner.couponsUuid, partnerInfoResult.couponUuid];
               }
               else{
                  partner.total = partnerInfoResult.total;
                  partner.totalCommission = partnerInfoResult.total * (partnerInfoResult.partnerPercentage / 100);

                  partner.couponsName = [partnerInfoResult.couponName];
                  partner.couponsUuid = [partnerInfoResult.couponUuid];
               }
            }
         });
      });
   }

   partnerUsers.data.forEach((partner) => {

      if (!partner.hasOwnProperty('total') && !partner.hasOwnProperty('totalCommission')){
         partner.total = 0
         partner.totalCommission = 0;
      }
   });

   return partnerUsers
}

// FOR THE PANEL
const getAllInfoAboutCommissionByUserUuidPanel = async (userUuid) => {
   let partnerUserInfo = { data: [] };
   let uuids = [];

   let usersData = [...store.salesCommissions.partnerUser.data];
   for (let users of usersData) {

      for (let user of users) {
         if (user.id == userUuid) {
            partnerUserInfo = user;
            break;
         }
      }
   }

   const coupons = await couponsRef.where('userId', '==', userUuid).get();

   // get all coupons related with the partner
   const partnerCouponsInfo = [];
   const couponsId = [];
   coupons.forEach((doc) => {
      let couponItem = doc.data();

      partnerCouponsInfo.push({
         partnerUuid: partnerUserInfo.id, couponUuid: couponItem.uuid, couponName: couponItem.name,
         usageHistory: couponItem.usageHistory, partnerPercentage: couponItem.partnerPercentage, totalByCoupon: 0
      });
      couponsId.push(couponItem.uuid);
   });

   const signatures = await signaturesRef.where('couponId', 'in', couponsId)
      .where('price', '>', 0).get();

   const month = getFourPreviousMonths(currentMonth);
   const months = [
      { type: 'currentMonth', month: currentMonth, totalByMonth: 0, totalCommissionByMonth: 0 },
      { type: 'previousMonth', month: month[3], totalByMonth: 0, totalCommissionByMonth: 0 },
      { type: 'twoMonthsAgo', month: month[2], totalByMonth: 0, totalCommissionByMonth: 0 },
      { type: 'threeMonthsAgo', month: month[1], totalByMonth: 0, totalCommissionByMonth: 0 },
      { type: 'fourMonthsAgo', month: month[0], totalByMonth: 0, totalCommissionByMonth: 0 },
   ]

   signatures.forEach((doc) => {
      let itemSignature = doc.data();

      const partnerInfo = partnerCouponsInfo.find((partner) => partner.couponUuid == itemSignature.couponId);

      if (itemSignature.lastPlatform === 'app_store' || itemSignature.hasOwnProperty('appStoreTransactions')){
         itemSignature.appStoreTransactions.forEach((transaction) => {

            const { year, month } = extractYearAndMonth(transaction.date);

            // Ensure the count is only for the current year and current month
            if (PAYMENT_APPLE_STATUS.includes(transaction.status.toUpperCase()) && year == currentYear && month <= currentMonth){
               updatePartnerCouponsAndMonthsArrays(partnerCouponsInfo, months, itemSignature, partnerInfo, currentMonth, month);
            }
         });
      }
      if (itemSignature.lastPlatform === 'paypal' || itemSignature.hasOwnProperty('payPalTransactions')){
         itemSignature.payPalTransactions.forEach((transaction) => {

            const { year, month } = extractYearAndMonth(transaction.date);

            // Ensure the count is only for the current year and current month
            if (PAYMENT_PAYPAL_STATUS.includes(transaction.status.toUpperCase()) && year == currentYear && month <= currentMonth){
               updatePartnerCouponsAndMonthsArrays(partnerCouponsInfo, months, itemSignature, partnerInfo, currentMonth, month);
            }
         });
      }
      if (itemSignature.lastPlatform === 'google_play' || itemSignature.hasOwnProperty('googlePlayTransactions')){
         itemSignature.googlePlayTransactions.forEach((transaction) => {

            const { year, month } = extractYearAndMonth(transaction.date);

            // Ensure the count is only for the current year and current month
            if (PAYMENT_GOOGLE_PLAY_STATUS.includes(transaction.status) && year == currentYear && month <= currentMonth){
               updatePartnerCouponsAndMonthsArrays(partnerCouponsInfo, months, itemSignature, partnerInfo, currentMonth, month);
            }
         });
      }
   });

   partnerUserInfo.monthsCommissionsValues = months;
   partnerUserInfo.allCouponsInfo = partnerCouponsInfo;

   partnerUserInfo.QytOfnewSigForTheCurrentMonth = 0;
   partnerCouponsInfo.forEach((partner) => {

      partner.usageHistory.forEach((newSig) => {
         const { year, month } = extractYearAndMonth(newSig.date);
         if (currentYear == year && currentMonth == month ){
            partnerUserInfo.QytOfnewSigForTheCurrentMonth++;
         }
      });
   });

   return partnerUserInfo
}

const updatePartnerCouponsAndMonthsArrays = (partnerCouponsInfoArray, monthsArray, itemSignature, partnerInfo, currentMonth,  month) => {
   partnerCouponsInfoArray.forEach((partnerCouponInfo) => {
      if (partnerCouponInfo.couponUuid == itemSignature.couponId && currentMonth == month)
         partnerCouponInfo.totalByCoupon += itemSignature.price;
   });
   monthsArray.forEach((result) => {
      if (result.month === month) {
         result.totalByMonth += itemSignature.price;
         result.totalCommissionByMonth += itemSignature.price * (partnerInfo.partnerPercentage / 100);
      }
   });
}

export default {
   getPartnersSalesCommissionsInfoTable,
   getAllPartnersPaginatedTable,
   getAllInfoAboutCommissionByUserUuidPanel
}

