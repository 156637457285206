import salesCommissionService from "@/modules/dashboard/modules/salesCommission/services/sales-commission-service";

export default {
   getAllPartnersPaginatedTable: async ({commit}, pageSize = 10) => {
      commit('SET_SALES_COMMISSION_LOADING', true)

      try {
         const { data: paginationInfo } = await salesCommissionService.getAllPartnersPaginatedTable(pageSize);

         commit
         (
            'SET_SALES_COMMISSION_PAGINATION_INFO',
            { totalPages: paginationInfo.totalPages, data: paginationInfo.data, tokenPages: paginationInfo.tokens }
         )
      }
      catch (e) {
         console.log('Error get all sales commission paginated: ', e)
      }
      finally {
         commit('SET_SALES_COMMISSION_LOADING', false)
      }

   }
}
