import { db } from "../../../plugins/firebase";
import {
   getMonthName,
   getFourPreviousMonths, getLatestDateButTheSameYear, getOldestDateButTheSameYear, extractYearAndMonth
} from "../../../utils/report-service-methods";
import {
   PAYMENT_GOOGLE_PLAY_STATUS,
   PAYMENT_APPLE_STATUS,
   PAYMENT_PAYPAL_STATUS
} from "../../../utils/payment-status";
import { PRICE_DEFAULT } from "../../../utils/payment-price";
import { FREE_PLAN_UUID } from "@/utils/free-plan-uuid";

const commentsRef = db.collection('comments');
const couponsRef = db.collection('coupons');
const plansRef = db.collection('plans');
const usersRef = db.collection('users');
const signaturesRef = db.collection('signatures');

//PARTNER VIEW

const getQtyOfTimesByMonthThePartnerCouponWasUsed = async (partnerId) => {
   const coupons = await couponsRef.where('userId', '==', partnerId).get();

   const currentDate = new Date();
   const currentYear = currentDate.getFullYear();
   const currentMonth = currentDate.getMonth() + 1;

   const results = {};

   /*Object format
      2024-1: 0
      2024-2: 0
      2024-3: 0
      2024-4: 0
   */
   // Initialize all previous months of the current year with zero counts
   for (let month = 1; month <= currentMonth; month++) {
      const key = `${currentYear}-${month}`; // Format "YYYY-MM"
      results[key] = 0;
   }

   coupons.forEach((doc) => {
      let item = doc.data();

      item.usageHistory.forEach((history) => {
         const date = new Date(history.date);
         const year = date.getFullYear();
         const month = date.getMonth() + 1;

         // Ensure counting is only for the current year and up to the current month
         if (year === currentYear && month <= currentMonth) {
            const key = `${year}-${month}`; // Format "YYYY-MM"

            // Increment count for the month
            results[key]++;
         }
      });
   });

   return results;
}

const getTotalOfTimesThePartnerCouponWasUsed = async (partnerId) => {
   const coupons = await couponsRef.where('userId', '==', partnerId).get();

   let total = 0;
   coupons.forEach((doc) => {
      let item = doc.data();

      total += item.usageQuantity;
   });

   return total;
}

//ADM VIEW

const reportMonthly = async (year) => {
   const coupons = await couponsRef.get();
   const plans = await plansRef.get();
   const users = await usersRef.where('profile', "==", "partner").get();
   const signatures = await signaturesRef.get();

   const plansArray= [];
   plans.forEach((doc) => {
      let item = doc.data();
      plansArray.push(item);
   });

   const couponsArray= [];
   coupons.forEach((doc) => {
      let item = doc.data();
      couponsArray.push(item);
   });

   const partnersArray = [];
   users.forEach((doc) => {
      let item = doc.data();
      partnersArray.push(item);
   });

   const date = new Date();
   const currentMonth = date.getMonth() + 1;

   let results = [];
   signatures.forEach((doc) => {
      let item = doc.data();

      const coupon = couponsArray.find((coupon) => coupon.uuid === item.couponId);

      if (coupon) {
         const partner = partnersArray.find((partner) => partner.id === coupon.userId);
         const plan = plansArray.find((plan) => plan.uuid === coupon.planUuid);

         const latestSigDate = getLatestDateButTheSameYear(item.signaturesDate, year);
         const signedMonth = latestSigDate.getMonth() + 1;
         const signedYear = latestSigDate.getFullYear();

         if (signedYear == year)
         {
            const foundedResult = results.find((result) => result.month === signedMonth && result.couponId === coupon.uuid);

            if (foundedResult){
               results.map((result) => {
                  if (result.month === signedMonth && result.couponId === coupon.uuid){
                     result.usageQuantity++;
                  }
               });
            }
            else{
               results.push(
                  {
                     month: signedMonth,
                     monthName: getMonthName(signedMonth),
                     clientId: item.userId,
                     partnerName: partner.name,
                     couponId: coupon.uuid,
                     couponName: coupon.name,
                     discountPercent: `${parseInt(plan.percent)}%`,
                     usageQuantity: 1,
                     discountedAmount: 0,
                     collectedAmount: 0
                  });
            }

            if (item.lastPlatform === 'app_store'){
               item.appStoreTransactions.forEach((transaction) => {

                  const { year: transactionYear, month: transactionMonth } = extractYearAndMonth(transaction.date);

                  // Ensure the count is only for the current year and current month
                  if (PAYMENT_APPLE_STATUS.includes(transaction.status.toUpperCase())
                        && transactionYear == year && transactionMonth <= currentMonth) {

                     results.map((result) => {
                        if (result.month === transactionMonth && result.couponId === coupon.uuid){
                           result.discountedAmount += (PRICE_DEFAULT - item.price);
                           result.collectedAmount += item.price;
                        }
                     });
                  }
               });
            } else if (item.lastPlatform === 'paypal'){
               item.payPalTransactions.forEach((transaction) => {

                  const { year: transactionYear, month: transactionMonth } = extractYearAndMonth(transaction.date);

                  // Ensure the count is only for the current year and current month
                  if (PAYMENT_PAYPAL_STATUS.includes(transaction.status.toUpperCase())
                        && transactionYear == year && transactionMonth <= currentMonth) {

                     results.map((result) => {
                        if (result.month === transactionMonth && result.couponId === coupon.uuid){
                           result.discountedAmount += (PRICE_DEFAULT - item.price);
                           result.collectedAmount += item.price;
                        }
                     });
                  }
               });
            } else {
               item.googlePlayTransactions.forEach((transaction) => {

                  const { year: transactionYear, month: transactionMonth } = extractYearAndMonth(transaction.date);

                  // Ensure the count is only for the current year and current month
                  if (PAYMENT_GOOGLE_PLAY_STATUS.includes(transaction.status)
                        && transactionYear == year && transactionMonth <= currentMonth) {

                     results.map((result) => {
                        if (result.month === transactionMonth && result.couponId === coupon.uuid){
                           result.discountedAmount += (PRICE_DEFAULT - item.price);
                           result.collectedAmount += item.price;
                        }
                     });
                  }
               });
            }
         }
      }
   });

   return results;
}

const reportYearly = async (year) => {
   year = parseInt(year);
   const plans = await plansRef.get();
   const signatures = await signaturesRef.get();

   const plansArray = [];
   plans.forEach((doc) => {
      let item = doc.data();
      plansArray.push(item);
   });

   const date = new Date();
   const currentMonth = date.getMonth() + 1;

   /*Object format
      2024-1: 0
      2024-2: 0
      2024-3: 0
      ....
   */
   const resultsObj = {};
   // Initialize all previous months of the current year with zero counts
   for (let month = 1; month <= currentMonth; month++) {
      const key = `${year}-${month}`; // Format "YYYY-MM"
      resultsObj[key] = 0;
   }

   signatures.forEach((doc) => {
      let item = doc.data();

      const oldestSigDate = getOldestDateButTheSameYear(item.signaturesDate, year);
      const oldestSignedYear = oldestSigDate.getFullYear();

      if (oldestSignedYear == year)
      {
         if (item.lastPlatform === 'app_store'){
            item.appStoreTransactions.forEach((transaction) => {

               const { year: transactionYear, month: transactionMonth } = extractYearAndMonth(transaction.date);

               // Ensure the count is only for the current year and current month
               if (PAYMENT_APPLE_STATUS.includes(transaction.status.toUpperCase()) && transactionYear == year && transactionMonth <= currentMonth) {
                  const key = `${year}-${transactionMonth}`; // Format "YYYY-MM"

                  // adding for the month
                  resultsObj[key] += item.price;
               }
            });
         } else if (item.lastPlatform === 'paypal'){
            item.payPalTransactions.forEach((transaction) => {

               const { year: transactionYear, month: transactionMonth } = extractYearAndMonth(transaction.date);

               // Ensure the count is only for the current year and current month
               if (PAYMENT_PAYPAL_STATUS.includes(transaction.status.toUpperCase()) && transactionYear == year && transactionMonth <= currentMonth) {
                  const key = `${year}-${transactionMonth}`; // Format "YYYY-MM"

                  // adding for the month
                  resultsObj[key] += item.price;
               }
            });
         } else {
            item.googlePlayTransactions.forEach((transaction) => {

               const { year: transactionYear, month: transactionMonth } = extractYearAndMonth(transaction.date);

               // Ensure the count is only for the current year and current month
               if (PAYMENT_GOOGLE_PLAY_STATUS.includes(transaction.status) && transactionYear == year && transactionMonth <= currentMonth) {
                  const key = `${year}-${transactionMonth}`; // Format "YYYY-MM"

                  // adding for the month
                  resultsObj[key] += item.price;
               }
            });
         }
      }
   });

   return resultsObj;
}

const getPartnersChartInfo = async () => {
   const coupons = await couponsRef.get();
   const users = await usersRef.where('profile', "==", "partner").get();

   const partnersArray = [];
   users.forEach((doc) => {
      let item = doc.data();

      partnersArray.push(item);
   });

   const currentDate = new Date();
   const currentMonth = (currentDate.getMonth() + 1);

   const results = [];
   coupons.forEach((doc) => {
      let item = doc.data();

      item.usageHistory.forEach((coupon) => {
         const partner = partnersArray.find((partner) => partner.id === item.userId);

         const dateString = coupon.date;
         const clientSignedDate = new Date(dateString);
         const clientSignedMonth = clientSignedDate.getMonth() + 1;

         if (currentMonth === clientSignedMonth){
            const foundedResult = results.find((res) => res.partnerUid === item.userId);

            //if found is because the partner have more than one coupon active
            if (foundedResult){
               results.map((result) => {
                  if (result.partnerUid === item.userId)
                     result.qtyCoupons += 1;
               });
            }
            else
               results.push({ partnerUid: item.userId, couponName: `${item.name} - ${partner.name}`, qtyCoupons: 1 });
         }
      });
   });

   return results
}

const getYearsFromSignatures = async () => {
   const signatures = await signaturesRef.get();

   if (signatures.size === 0)
      return null;

   const years = [];
   signatures.forEach((doc) => {
      let item = doc.data();

      const yearsTemp = [];
      item.signaturesDate.forEach((date) => {
         const dateString = date;
         const signatureDate = new Date(dateString);
         const signatureYear = signatureDate.getFullYear();

         yearsTemp.push(signatureYear);
      });

      const smallestYear = Math.min(...yearsTemp);
      const foundedYear = years.find((year) => year === smallestYear);
      if (foundedYear === undefined && smallestYear !== Infinity)
         years.push(smallestYear);
   });

   return years;
}

const qtySignaturesByMonth = async () => {
   const signatures = await signaturesRef.get();

   const now = new Date();
   const currentMonth = (now.getMonth() + 1);
   const currentYear = now.getFullYear();

   const fourPreviousMonths = getFourPreviousMonths(currentMonth);
   const results = [
      { type: 'currentMonth', month: currentMonth, qty: 0 },
      { type: 'previousMonth', month: fourPreviousMonths[3], qty: 0 },
      { type: 'twoMonthsAgo', month: fourPreviousMonths[2], qty: 0 },
      { type: 'threeMonthsAgo', month: fourPreviousMonths[1], qty: 0 }
   ]

   signatures.forEach((doc) => {
      let item = doc.data();

      const latestSigDate = getLatestDateButTheSameYear(item.signaturesDate, currentYear);
      const sigMonth = latestSigDate.getMonth() + 1;
      const sigYear = latestSigDate.getFullYear();

      if (sigYear === currentYear) {
         results.forEach((result) => {
            if (result.month === sigMonth)
               result.qty++;
         });
      }
   });

   return results;
}

const totalSignaturesByMonth = async () => {
   const signatures = await signaturesRef.get();

   const now = new Date();
   const currentMonth = (now.getMonth() + 1);
   const currentYear = now.getFullYear();

   const month = getFourPreviousMonths(currentMonth);
   const results = [
      { type: 'currentMonth', month: currentMonth, total: 0, percentage: 0 },
      { type: 'previousMonth', month: month[3], total: 0, percentage: 0 },
      { type: 'twoMonthsAgo', month: month[2], total: 0, percentage: 0 },
      { type: 'threeMonthsAgo', month: month[1], total: 0, percentage: 0 },
      { type: 'fourMonthsAgo', month: month[0], total: 0, percentage: 0 },
   ]

   signatures.forEach((doc) => {
      let item = doc.data();

      if (item.lastPlatform === 'app_store' || item.hasOwnProperty('appStoreTransactions')){
         item.appStoreTransactions.forEach((transaction) => {

            const { year, month } = extractYearAndMonth(transaction.date);

            // Ensure the count is only for the current year and current month
            if (PAYMENT_APPLE_STATUS.includes(transaction.status.toUpperCase()) && year == currentYear && month <= currentMonth){
               results.map((result) => {
                  if (result.month === month)
                     result.total += item.price;
               });
            }
         });
      }
      if (item.lastPlatform === 'paypal' || item.hasOwnProperty('payPalTransactions')){
         item.payPalTransactions.forEach((transaction) => {

            const { year, month } = extractYearAndMonth(transaction.date);

            // Ensure the count is only for the current year and current month
            if (PAYMENT_PAYPAL_STATUS.includes(transaction.status.toUpperCase()) && year == currentYear && month <= currentMonth){
               results.map((result) => {
                  if (result.month === month)
                     result.total += item.price;
               });
            }
         });
      }
      if (item.lastPlatform === 'google_play' || item.hasOwnProperty('googlePlayTransactions')){
         item.googlePlayTransactions.forEach((transaction) => {

            const { year, month } = extractYearAndMonth(transaction.date);

            // Ensure the count is only for the current year and current month
            if (PAYMENT_GOOGLE_PLAY_STATUS.includes(transaction.status) && year == currentYear && month <= currentMonth){
               results.map((result) => {
                  if (result.month === month)
                     result.total += item.price;
               });
            }
         });
      }
   });

   results.map((item, index) => {
      item.total = item.total.toLocaleString('pt-br', {minimumFractionDigits: 2});
   });

   results.pop();
   return results.reverse();
}

const totalMonthlyRent = async () => {
   const signatures = await signaturesRef.get();

   const currentDate = new Date();
   const currentMonth = (currentDate.getMonth() + 1);
   const currentYear = currentDate.getFullYear();

   let total = 0;
   signatures.forEach((doc) => {
      let item = doc.data();

      const currentDate = new Date();
      const currentMonth = currentDate.getMonth() + 1;
      const currentYear = currentDate.getFullYear();

      if (item.lastPlatform === 'app_store'){
         item.appStoreTransactions.forEach((transaction) => {

            const { year, month } = extractYearAndMonth(transaction.date);

            // Ensure the count is only for the current year and current month
            if (PAYMENT_APPLE_STATUS.includes(transaction.status.toUpperCase()) && year == currentYear && month == currentMonth)
               total += item.price;
         });
      } else if (item.lastPlatform === 'paypal'){
         item.payPalTransactions.forEach((transaction) => {

            const { year, month } = extractYearAndMonth(transaction.date);

            // Ensure the count is only for the current year and current month
            if (PAYMENT_PAYPAL_STATUS.includes(transaction.status.toUpperCase()) && year == currentYear && month == currentMonth)
               total += item.price;
         });
      } else {
         item.googlePlayTransactions.forEach((transaction) => {

            const { year, month } = extractYearAndMonth(transaction.date);

            // Ensure the count is only for the current year and current month
            if (PAYMENT_GOOGLE_PLAY_STATUS.includes(transaction.status) && year == currentYear && month == currentMonth)
               total += item.price;
         });
      }
   });

   return total
}

const totalComments = async () => {
   const comments = await commentsRef.get();
   const total = comments.size;

   return total;
}

const totalFreeSignatures = async () => {
   const sigs = await signaturesRef.get();
   const coupons = await couponsRef.get();
   let total = 0;

   sigs.forEach((doc) => {
      let item = doc.data();
      if (item.status.toLowerCase() === 'active'){

         if (item.hasOwnProperty('couponId')){
            coupons.forEach((couponDoc) => {
               let coupon = couponDoc.data();
               if ((item.couponId == coupon.uuid) && (coupon.planUuid == FREE_PLAN_UUID))
                  total++;
            });
         }
         else
            total++;
      }
   });

   return total;
}

const totalPaidSignatures = async () => {
   const sigs = await signaturesRef.get();
   const coupons = await couponsRef.get();
   let total = 0;

   sigs.forEach((doc) => {
      let item = doc.data();
      if (item.status.toLowerCase() === 'active'){

         if (item.hasOwnProperty('couponId')){
            coupons.forEach((couponDoc) => {
               let coupon = couponDoc.data();
               if ((item.couponId == coupon.uuid) && (coupon.planUuid != FREE_PLAN_UUID))
                  total++;
            });
         }
         else
            total++;
      }
   });

   return total;
}

const totalClients = async () => {
   const users = await usersRef.get();
   let total = 0;

   users.forEach((doc) => {
      let item = doc.data();
      if (item.profile.toLowerCase() === 'client')
         total++;
   });

   return total;
}


export default {
   getQtyOfTimesByMonthThePartnerCouponWasUsed,
   getTotalOfTimesThePartnerCouponWasUsed,
   getPartnersChartInfo,
   getYearsFromSignatures,
   reportMonthly,
   reportYearly,
   qtySignaturesByMonth,
   totalSignaturesByMonth,
   totalMonthlyRent,
   totalComments,
   totalClients,
   totalFreeSignatures,
   totalPaidSignatures
}
