export default {
   SET_SALES_COMMISSION_PAGINATION_INFO: (state, info) => {
      state.salesCommissions.partnerUser.pagination.totalPages = info.totalPages
      state.salesCommissions.partnerUser.data = info.data
      state.salesCommissions.partnerUser.pagination.tokenPages = info.tokenPages
   },

   SET_SALES_COMMISSION_CURRENT_PAGE: (state, currentPage) => {
      state.salesCommissions.partnerUser.pagination.currentPage = currentPage
   },

   SET_SALES_COMMISSION_LOADING: (state, loading) => {
      state.salesCommissions.partnerUser.loading = loading
   },
}
