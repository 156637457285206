import reportService from "../services/report-service";

export default {
   getAdminTableInformation: async ({ commit }) => {
      commit("SET_LOADING_INFORMATION", true);

      try {
         const tableInfo = await reportService.getPartnersChartInfo();
         commit("SET_TABLE_INFO", tableInfo);

         const tableLabels = [];
         const tableData = [];
         tableInfo.forEach((item) => {
            tableLabels.push(item.couponName);
            tableData.push(item.qtyCoupons);
         });
         commit("SET_TABLE_LABELS", tableLabels);
         commit("SET_TABLE_DATA", tableData);

         const totalMonthlyRent = await reportService.totalMonthlyRent();
         commit("SET_TOTAL_MONTHLY_RENT", totalMonthlyRent);

         const totalClients = await reportService.totalClients();
         commit("SET_TOTAL_CLIENTS", totalClients);

         const totalComments = await reportService.totalComments();
         commit("SET_TOTAL_COMMENTS", totalComments);

         const totalFreeSignatures = await reportService.totalFreeSignatures();
         commit("SET_TOTAL_FREE_SIGNATURES", totalFreeSignatures);

         const totalPaidSignatures = await reportService.totalPaidSignatures();
         commit("SET_TOTAL_PAID_SIGNATURES", totalPaidSignatures);

         const sigsPerMonth = await reportService.totalSignaturesByMonth();
         commit("SET_TOTAL_SIGNATURES_PER_MONTH", sigsPerMonth);

         const qtySigsPerMonth = await reportService.qtySignaturesByMonth();
         commit("SET_QTY_SIGNATURES_PER_MONTH", qtySigsPerMonth);

      } catch (e) {
         console.error("Error getAdminTableInformation: ", e);
      } finally {
         commit("SET_LOADING_INFORMATION", false);
      }
   }
};
