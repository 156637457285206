export default {
   SET_TABLE_INFO: (state, info) => {
      state.reports.getTableInfo = info;
   },

   SET_TABLE_LABELS: (state, labels) => {
      state.reports.tableLabels = labels;
   },

   SET_TABLE_DATA: (state, data) => {
      state.reports.tableData = data;
   },

   SET_TOTAL_MONTHLY_RENT: (state, total) => {
      state.reports.totalMonthlyRent = total;
   },

   SET_TOTAL_CLIENTS: (state, total) => {
      state.reports.totalClients = total;
   },

   SET_TOTAL_COMMENTS: (state, total) => {
      state.reports.totalComments = total;
   },

   SET_TOTAL_FREE_SIGNATURES: (state, total) => {
      state.reports.totalFreeSignatures = total;
   },

   SET_TOTAL_PAID_SIGNATURES: (state, total) => {
      state.reports.totalPaidSignatures = total;
   },

   SET_TOTAL_SIGNATURES_PER_MONTH: (state, sigsPerMonth) => {
      state.reports.totalSignaturesByMonth = sigsPerMonth;
   },

   SET_QTY_SIGNATURES_PER_MONTH: (state, qtyPerMonth) => {
      state.reports.qtySignaturesByMonth = qtyPerMonth;
   },

   SET_LOADING_INFORMATION: (state, status) => {
      state.reports.loadingInformation = status;
   }
};
