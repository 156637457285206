import axiosClient from '@/axios'
import { db } from "../../../plugins/firebase";
import {formatDate} from "../../../utils/date-times";

const routeAuthenticated = '/auth/coupons';
const couponsRef = db.collection('coupons');

const index = async () => {
   return await axiosClient.get(`${routeAuthenticated}/index`);
}

const getAllPaginated = async (pageSize = 10, pageToken = '') => {
   return await axiosClient.get(`${routeAuthenticated}/get-all-paginated?pageSize=${pageSize}&pageToken=${pageToken}`);
}

const getAllValid = async () => {
   const today = new Date();
   const timestampUnixToday = Math.floor(today.getTime() / 1000);

   const validCoupons = await couponsRef
      .where('expiryDateTimestamp', ">=", timestampUnixToday)
      .get();

   let arrayCupons = [];
   validCoupons.forEach((doc) => {
      let item = doc.data();

      if (item.activationDateTimestamp <= timestampUnixToday) {

         //verify usage limit
         if (item.haveUsageLimit === false)
            arrayCupons.push(item);
         else {
            if (item.usageQuantity < item.limit)
               arrayCupons.push(item);
         }
      }
   });

   return arrayCupons;
}

const get = async uuid => {
   return await axiosClient.get(`${routeAuthenticated}/get/${uuid}`);
}

const getPaginationInfo = async (pageSize) => {
   return await axiosClient.get(`${routeAuthenticated}/get-pagination-info?pageSize=${pageSize}`)
}

const create = async coupon => {

   //verify if coupon already exists
   const coupons = await couponsRef
      .where('name', '==', coupon.name)
      .get();

   if (coupons.size > 0)
      return false;

   await axiosClient.post(`${routeAuthenticated}/create`, coupon);
   return true;
}

const edit = async (coupon, uuid) => {

   //verify if coupon already exists
   let alreadyExists = false;
   const coupons = await couponsRef
      .where('name', '==', coupon.name)
      .get();

   if (coupons.size > 0) {
      coupons.forEach((doc) => {
         const item = doc.data();

         if (item.uuid !== coupon.uuid) {
            alreadyExists = true;
            return;
         }
      })
   }

   if (alreadyExists)
      return false;

   await axiosClient.put(`${routeAuthenticated}/edit/${uuid}`, coupon);
   return true;
}

const destroy = async uuid => {
   return await axiosClient.delete(`${routeAuthenticated}/delete/${uuid}`);
}

const disable = async uuid => {
   return await axiosClient.put(`${routeAuthenticated}/disable/${uuid}`);
}

const search = async (name) => {
   const coupons = await couponsRef.get();

   let results = [];
   coupons.forEach((doc) => {
      let item = doc.data();

      if (item.name) {
         const couponNormalized = item.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
         const searchNormalized = name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

         if (couponNormalized.includes(searchNormalized))
            results.push(item);
      }
   });

   const currentDate = new Date();
   results = results.map((item) => {
      item.activationDateFormatted = formatDate(item.activationDate);
      item.expiryDateFormatted = formatDate(item.expiryDate);

      const expirydate = new Date(item.expiryDate);
      const activationDate = new Date(item.activationDate);

      let validationStatus = '';
      if (currentDate > expirydate)
         validationStatus = 'expired';
      else if (activationDate > currentDate)
         validationStatus = 'inactive';
      else
         validationStatus = 'active';
      item.validationStatus = validationStatus;

      return item;
   })

   return results;
}

export default {
   index,
   get,
   getAllPaginated,
   getAllValid,
   getPaginationInfo,
   create,
   edit,
   destroy,
   disable,
   search
}
