import axiosClient from '@/axios'
import { db } from "../../../../../plugins/firebase";
import {v4 as uuidV4} from "uuid";

const route = '/users';
const routeAuthenticated = '/auth/users';
const usersRef = db.collection('users');

const get = async email => {
   return await axiosClient.get(`${routeAuthenticated}/get/${email}`);
}

const getPhotoUrl = async (userId) => {
   return await axiosClient.get(`${route}/get-photo-url/${userId}`);
}

const getAll = async (type, pageSize = 10) => {
   const users = await usersRef.orderBy('createdAt', 'desc').get();

   let userTypes = [];
   if (type === 'client')
      userTypes.push('client');
   else
      userTypes.push('admin', 'employee', 'partner');

   const results = [];
   users.forEach((doc) => {
      let item = doc.data();
      if (userTypes.includes(item.profile))
         results.push(item);
   });

   const totalPages = results.length / pageSize > 1 ? Math.ceil(results.length / pageSize) : 1;

   const tokenPages = [];
   const finalResults = [];
   for (let i = 1; i <= totalPages; i++) {
      tokenPages.push({ page: i, token: uuidV4() });

      let internalArray = [];
      for (let j = 0; j < pageSize; j++) {
         const position = finalResults.length > 0 ? finalResults.length * (j + 10) : j ;
         if (results[position] === undefined)
            break;

         internalArray.push(results[position]);
      }
      finalResults.push(internalArray)
   }

   const result = {
      totalPages,
      data: finalResults,
      tokenPages
   };

   return result;
}

const getAllPartners = async () => {
   const users = await usersRef.orderBy('createdAt', 'desc').get();

   let userType = 'partner';

   const results = [];
   users.forEach((doc) => {
      let item = doc.data();
      if (userType === item.profile)
         results.push(item);
   });

   return results;
}

const alreadyRegistered = async email => {
   return await axiosClient.get(`${route}/already-registered/${email}`);
}

const create = async user => {
   return await axiosClient.post(`${routeAuthenticated}/create`, user);
}

const includeBankingInformation = async (bankInfo, userId) => {
   return await axiosClient.put(`${routeAuthenticated}/include-banking-info/${userId}`, bankInfo);
}

const edit = async (user, oldEmail) => {
   return await axiosClient.put(`${routeAuthenticated}/edit/${oldEmail}`, user);
}

const disable = async email => {
   return await axiosClient.get(`${routeAuthenticated}/disable/${email}`);
}

const search = async (type, name) => {
   const users = await usersRef.get();

   let userTypes = [];
   if (type === 'client')
      userTypes.push('client');
   else
      userTypes.push('admin', 'employee', 'partner');

   const results = [];
   users.forEach((doc) => {
      let item = doc.data();
      if (userTypes.includes(item.profile)) {
         const nameNormalized = item.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
         const searchNormalized = name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

         if (nameNormalized.includes(searchNormalized))
            results.push(item);
      }
   });

   const finalResults = [];
   finalResults.push(results);

   return finalResults;
}

export default {
   alreadyRegistered,
   getAll,
   getAllPartners,
   get,
   getPhotoUrl,
   create,
   includeBankingInformation,
   edit,
   disable,
   search
}
